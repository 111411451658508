// Navbar.js
import React from 'react';
import { AppBar, Toolbar, Modal, Typography, TextField, Box, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlaceIcon from '@mui/icons-material/Place';
import LanguageIcon from '@mui/icons-material/Language';
import Search from './Search';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '80%',
    bgcolor: 'rgb(246, 245, 242)',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: "scroll", 
    maxWidht: "100%", 
    overflowX: 'hidden', 
    maxHeight: "95%"
  };

  return (
    <AppBar position="sticky" sx={{ width: '100%', borderRadius: 10 }}>
      <Toolbar sx={{ justifyContent: 'space-between', borderRadius: 10 }}>
        <Link to='/'>
        <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <img src="https://sportivebuddy.com/images/logo-sportive.svg" alt="Logo" style={{ height: 30 }} />
        </Typography>
        </Link>
        <TextField
          label="Search in Estonia..."
          variant="outlined"
          sx={{
            ml: 'auto',
            mr: 'auto',  // Center horizontally
            display: { xs: 'none', md: 'block' }, // Show only on md screens and above
            input: {textAlign: "center"}
          }}
          fullWidth
          style={{width: '50vh'}}
          onClick={handleOpen}
        />
        <Box sx={{ display: { md: 'flex' } }}>
          <Button color="inherit" startIcon={<PlaceIcon />}>Estonia</Button>
          <Button color="inherit" startIcon={<LanguageIcon />}></Button>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button color="inherit" startIcon={<AccountCircleIcon />}>Log In</Button>
          </Box>
        </Box>
      </Toolbar>
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Search></Search>
      </Box>
    </Modal>
    </AppBar>
  );
}

export default Navbar;
