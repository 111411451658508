import React, { useState } from 'react';
import { Container, Card, CardActionArea, CardMedia, Box, Typography, Button, TextField } from '@mui/material';
import Navbar from '../components/Navbar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Navigate} from 'react-router-dom'

const Book = () => {
    const [activeStep, setActiveStep] = useState(1);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const steps = [
        'Select venue and time',
        'Booking details',
        'Payment',
        'Confirmed',
    ];

    /*const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };*/

    const handleNext = () => {
        if (activeStep === 1 && email === '') {
            setEmailError(true);
        } else {
            setEmailError(false);
            if (activeStep < steps.length - 1) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (e.target.value !== '') {
            setEmailError(false);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Navbar />
                <p></p>
                <Typography variant="h4" sx={{ display: { xs: 'block', md: 'block' }, textAlign: 'center', mb: 4 }}>
                    Indoor Football Field
                </Typography>
                <Container sx={{ mt: 2 }}>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 1 && (
                            <Box sx={{ mt: 3 }}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    error={emailError}
                                    helperText={emailError ? 'Email is required' : ''}
                                    fullWidth
                                    required
                                />
                            </Box>
                        )}
                        {activeStep === 0 && (
                            <Navigate to='/ee/Tallinn/org-1/venue-1/'></Navigate>
                        )}
                        {activeStep === 2 && (
                            <Container>
                            <Card>
                            <CardActionArea onClick={()=>{window.location.href='https://login.swedbank.ee/auth/oauth/v3/authorize'}}>
                              <CardMedia
                                component="img"
                                image={"https://login.swedbank.ee/webjars/webcomponents/2.18.0/assets/images/swedbank-4x.png"}
                                alt="Image"
                                title="Click to pay"
                                style={{width: '300px'}}
                              />
                            </CardActionArea>
                          </Card>
                          <p></p>
                          <Card>
                            <CardActionArea onClick={()=>{window.location.href='https://login.swedbank.ee/auth/oauth/v3/authorize'}}>
                              <CardMedia
                                component="img"
                                image={"https://login.swedbank.ee/webjars/webcomponents/2.18.0/assets/images/swedbank-4x.png"}
                                alt="Image"
                                title="Click to pay"
                                style={{width: '300px'}}
                              />
                            </CardActionArea>
                          </Card>
                          <p></p>
                          <Card>
                            <CardActionArea onClick={()=>{window.location.href='https://login.swedbank.ee/auth/oauth/v3/authorize'}}>
                              <CardMedia
                                component="img"
                                image={"https://login.swedbank.ee/webjars/webcomponents/2.18.0/assets/images/swedbank-4x.png"}
                                alt="Image"
                                title="Click to pay"
                                style={{width: '300px'}}
                              />
                            </CardActionArea>
                          </Card>
                          </Container>
                          
                          
                        )}
                    </Box>
                </Container>
            </Box>
            <Container>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                {/*<Button
                    variant="contained"
                    color="primary"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                >
                    Back
                    </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={activeStep === steps.length - 1}
                >
                    Next
                </Button>*/}
                <Button onClick={handleNext} variant="contained" fullWidth color="primary">
       Continue
    </Button>
            </Box>
            
            </Container>
        </Box>
    );
};

export default Book;
