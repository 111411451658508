import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';

const GridView = ({ venueData }) => {
    return (
        <div style={{ maxWidth: 1024, margin: '0 auto' }}>
        <Grid container spacing={2}>
            {venueData.venues.map((venue, idx) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                    <Card className="slide-card" sx={{ maxWidth: 260, margin: '0 auto', borderRadius: 10, backdropFilter: 'blur(8px)' }}>
                        <CardMedia
                            component="img"
                            height="140"
                            image={venue.image}
                            alt={venue.name}
                            sx={{ borderRadius: '10px 10px 0 0' }} // Round only top corners of the image
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                                {venue.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {venue.rating} ★
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {venue.deliveryFee} • {venue.deliveryTime}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
        </div>
    );
}

export default GridView;