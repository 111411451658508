import React, {useState} from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Container, Button } from '@mui/material';
import Navbar from '../components/Navbar';
import Slideshow from '../components/Slideshow';
import VenueView from '../components/VenueView';
import { Link } from 'react-router-dom'
import ScheduleSelector from 'react-schedule-selector'
//import dayjs from 'dayjs';

const Venue = () => {

  // booking
  const [getSchedule, setSchedule] = useState([]);
  const booked = {
    '2024-07-03T12:00:00.000Z': true
  }

  const handleChange = newSchedule => {
    setSchedule(newSchedule)
  }

  const renderCustomDateCell = (time, selected, innerRef) => {
      const taken = booked[time.toISOString()]
      return (
      <div style={{ borderRadius: 3, width: '100%', textAlign: 'center', height: '25px', backgroundColor: taken ? '#ff8873': selected ? '#b3f32c' : '#dddddd' }} ref={innerRef}>
      {taken ? '' : ''}
      </div>
    )
  }

  // day
  /*const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  const clampDate = (date) => {
    const today = dayjs();
    const dayDiff = date.diff(today, 'day');
    const clampedDayDiff = Math.ceil(dayDiff / 3) * 3;
    return today.add(clampedDayDiff, 'day');
  };

  const today = dayjs();
  const minDate = today;
  const maxDate = today.add(14, 'day');
      */

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Navbar />
        <Container sx={{ mt: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <Box sx={{ flex: 2 }}>
            <Card sx={{ position: 'relative', borderRadius: 3 }}>
              <CardMedia
                component="img"
                height="200"
                image="https://placehold.co/600x400"
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  borderRadius: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  p: 2,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                </Box>
                <CardContent sx={{ color: 'white' }}>
                  <Typography variant="h5">Indoor Football Field</Typography>
                  <Typography variant="body2">Test Facility</Typography>
                  <Typography variant="body2">
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: { xs: 'none', md: 'block' },
              ml: { md: 2 },
              mt: { xs: 2, md: 0 }
            }}
          >
            <Box
              sx={{
                position: 'relative',
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
                <Slideshow />
            </Box>
          </Box>
        </Container>
        <Container sx={{ display: { xs: 'block', md: 'none' }}}>
        <VenueView venueData={[
            {
                "category": "🛈 Info",
                "venues": [
                  {
                    "name": "Info 1",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400"
                  },
                  {
                    "name": "Info 2",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400"
                  },
                  {
                    "name": "Info 3",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400"
                  }
                ]
              },
          ]} />
          </Container>
      </Box>
      <Container sx={{ display: { xs: 'block', md: 'none', sm: 'none' }}}>
        <ScheduleSelector
          selection={getSchedule}
          onChange={handleChange}
          numDays={1}
          minTime={8}
          maxTime={22}
          dateFormat="ddd M/D"
          renderDateCell={renderCustomDateCell}
        />
    </Container>
    <Container sx={{ display: { xs: 'none', md: 'none', sm: 'block' }}}>
        <ScheduleSelector
          selection={getSchedule}
          onChange={handleChange}
          numDays={2}
          minTime={8}
          maxTime={22}
          dateFormat="ddd M/D"
          renderDateCell={renderCustomDateCell}
        />
    </Container>
    <Container sx={{ display: { xs: 'none', md: 'block', sm: 'none'  }}}>
        <ScheduleSelector
          selection={getSchedule}
          onChange={handleChange}
          numDays={4}
          minTime={8}
          maxTime={22}
          dateFormat="ddd M/D"
          renderDateCell={renderCustomDateCell}
        />
    </Container>
    <p></p>
    <Container >
    <Button component={Link} to='./book' variant="contained" fullWidth color="primary">
        Book Now
    </Button>
    </Container>
    <p></p>
    </Box>
  );
};

export default Venue;
