import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Navbar from '../components/Navbar';
import VenueView from '../components/VenueView';

const Home = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Navbar />
        <Container sx={{ mt: 2 }}>
          {/* Hero Title (visible only on desktop) */}
          <Typography variant="h4" sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'center', mb: 4 }}>
            Welcome to SportiveBuddy, this is a lorem ipsum
          </Typography>
          <VenueView venueData={[
            {
              "category": "🇪🇪 Popular in Tallinn",
              "venues": [
                {
                  "name": "Indoor Football Court",
                  "rating": 4.8,
                  "deliveryFee": "Test Facility",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/org-1/venue-1"
                },
                {
                  "name": "Indoor Football Court",
                  "rating": 4.8,
                  "deliveryFee": "Test Facility",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/org-1/venue-1"
                },
                {
                  "name": "Indoor Football Court",
                  "rating": 4.8,
                  "deliveryFee": "Test Facility",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/org-1/venue-1"
                },
                {
                  "name": "Indoor Football Court",
                  "rating": 4.8,
                  "deliveryFee": "Test Facility",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/org-1/venue-1"
                },
                {
                  "name": "Indoor Football Court",
                  "rating": 4.8,
                  "deliveryFee": "Test Facility",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/org-1/venue-1"
                },
                {
                  "name": "Indoor Football Court",
                  "rating": 4.8,
                  "deliveryFee": "Test Facility",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/org-1/venue-1"
                },
                {
                  "name": "Indoor Football Court",
                  "rating": 4.8,
                  "deliveryFee": "Test Facility",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/org-1/venue-1"
                },
                {
                  "name": "Indoor Football Court",
                  "rating": 4.8,
                  "deliveryFee": "Test Facility",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://placehold.co/600x400",
                  "link": "/ee/Tallinn/org-1/venue-1"
                },
              ]
            },
            {
              "category": "⚽ Football",
              "venues": [
                {
                  "name": "Test Facility",
                  "rating": 4.8,
                  "deliveryFee": "Mustamae",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://fs.ihu.edu.tr/siteler/spormerkezi.ihu.edu.tr/contents/6272e4951b4b3/0x0-dsc-5185-jpg.jpg",
                  "link": "/ee/Tallinn/org-1"
                },
                {
                  "name": "Test Facility",
                  "rating": 4.8,
                  "deliveryFee": "Mustamae",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://fs.ihu.edu.tr/siteler/spormerkezi.ihu.edu.tr/contents/6272e4951b4b3/0x0-dsc-5185-jpg.jpg",
                  "link": "/ee/Tallinn/org-1"
                },
                {
                  "name": "Test Facility",
                  "rating": 4.8,
                  "deliveryFee": "Mustamae",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://fs.ihu.edu.tr/siteler/spormerkezi.ihu.edu.tr/contents/6272e4951b4b3/0x0-dsc-5185-jpg.jpg",
                  "link": "/ee/Tallinn/org-1"
                },
                {
                  "name": "Test Facility",
                  "rating": 4.8,
                  "deliveryFee": "Mustamae",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://fs.ihu.edu.tr/siteler/spormerkezi.ihu.edu.tr/contents/6272e4951b4b3/0x0-dsc-5185-jpg.jpg",
                  "link": "/ee/Tallinn/org-1"
                },
                {
                  "name": "Test Facility",
                  "rating": 4.8,
                  "deliveryFee": "Mustamae",
                  "deliveryTime": "8am - 11pm",
                  "image": "https://fs.ihu.edu.tr/siteler/spormerkezi.ihu.edu.tr/contents/6272e4951b4b3/0x0-dsc-5185-jpg.jpg",
                  "link": "/ee/Tallinn/org-1"
                }
              ]
            }
          ]} />

        </Container>
      </Box>
    </Box>
  );
}

export default Home;
