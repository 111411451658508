import React from 'react';
import { Container, Box, Typography, TextField } from '@mui/material';
import VenueView from './VenueView';
import GridView from './GridView';

const Search = () => {
  return (
    <Container width='100%'>
      <Container sx={{ mt: 2 }}>
      <TextField
        label="Search in Estonia..."
        variant="outlined"
        sx={{
          ml: 'auto',
          mr: 'auto', // Center horizontally
          display: { xs: 'block', md: 'block' }, // Show only on md screens and above
          input: { textAlign: "center" }
        }}
        fullWidth
        style={{ width: '30vh' }} />
      <VenueView venueData={[
        {
          "category": "Organizations",
          "venues": [
            {
              "name": "TalTech Sporticlub",
              "rating": 4.8,
              "deliveryFee": "Test Facility",
              "deliveryTime": "8am - 11pm",
              "image": "https://placehold.co/600x400",
              "link": "/ee/Tallinn/org-1"
            },
            {
              "name": "TalTech Sporticlub",
              "rating": 4.8,
              "deliveryFee": "Test Facility",
              "deliveryTime": "8am - 11pm",
              "image": "https://placehold.co/600x400",
              "link": "/ee/Tallinn/org-1"
            },
            {
              "name": "TalTech Sporticlub",
              "rating": 4.8,
              "deliveryFee": "Test Facility",
              "deliveryTime": "8am - 11pm",
              "image": "https://placehold.co/600x400",
              "link": "/ee/Tallinn/org-1"
            },
            {
              "name": "TalTech Sporticlub",
              "rating": 4.8,
              "deliveryFee": "Test Facility",
              "deliveryTime": "8am - 11pm",
              "image": "https://placehold.co/600x400",
              "link": "/ee/Tallinn/org-1"
            },
            {
              "name": "TalTech Sporticlub",
              "rating": 4.8,
              "deliveryFee": "Test Facility",
              "deliveryTime": "8am - 11pm",
              "image": "https://placehold.co/600x400",
              "link": "/ee/Tallinn/org-1"
            },
            {
              "name": "TalTech Sporticlub",
              "rating": 4.8,
              "deliveryFee": "Test Facility",
              "deliveryTime": "8am - 11pm",
              "image": "https://placehold.co/600x400"
            },
          ]
        }
      ]} />
    </Container><Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" component="h2">
          Courts and fields
        </Typography>
      </Box><GridView venueData={{
        "venues": [
          {
            "name": "TalTech Sporticlub",
            "rating": 4.8,
            "deliveryFee": "Test Facility",
            "deliveryTime": "8am - 11pm",
            "image": "https://placehold.co/600x400"
          },
          {
            "name": "TalTech Sporticlub",
            "rating": 4.8,
            "deliveryFee": "Test Facility",
            "deliveryTime": "8am - 11pm",
            "image": "https://placehold.co/600x400"
          },
          {
            "name": "TalTech Sporticlub",
            "rating": 4.8,
            "deliveryFee": "Test Facility",
            "deliveryTime": "8am - 11pm",
            "image": "https://placehold.co/600x400"
          },
          {
            "name": "TalTech Sporticlub",
            "rating": 4.8,
            "deliveryFee": "Test Facility",
            "deliveryTime": "8am - 11pm",
            "image": "https://placehold.co/600x400"
          },
          {
            "name": "TalTech Sporticlub",
            "rating": 4.8,
            "deliveryFee": "Test Facility",
            "deliveryTime": "8am - 11pm",
            "image": "https://placehold.co/600x400"
          },
          {
            "name": "TalTech Sporticlub",
            "rating": 4.8,
            "deliveryFee": "Test Facility",
            "deliveryTime": "8am - 11pm",
            "image": "https://placehold.co/600x400"
          }
        ]
      }}
      ></GridView>
    </Container>
  );
}

export default Search;
