import React from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Chip, Container } from '@mui/material';
import Navbar from '../components/Navbar';
import VenueView from '../components/VenueView';

const Org = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Navbar />
        <Container sx={{ mt: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <Box sx={{ flex: 2 }}>
            <Card sx={{ position: 'relative', borderRadius: 3 }}>
              <CardMedia
                component="img"
                height="200"
                image="https://boluspor.org.tr/dacegug/2022/07/halisaha-tesisleri-1.jpg"
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  borderRadius: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  p: 2,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                </Box>
                <CardContent sx={{ color: 'white' }}>
                  <Typography variant="h5">Test Facility</Typography>
                  <Typography variant="body2">4.5 (395) · Shower · Free Ball · Scheduling · More info</Typography>
                  <Typography variant="body2">
                    <Chip label="6-7 pm" sx={{ mr: 1 }} />
                    <Chip label="7-8 pm" sx={{ mr: 1 }} />
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: { xs: 'none', md: 'block' },
              ml: { md: 2 },
              mt: { xs: 2, md: 0 }
            }}
          >
            <Box
              sx={{
                position: 'relative',
                height: '100%',
                borderRadius: 3,
                overflow: 'hidden',
              }}
            >
              <iframe
                title="Google Maps"
                width="100%"
                height="100%"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.83543450993!2d144.95373531567995!3d-37.81627974202183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577d89837d4e5e4!2sVictoria%20Harbour%2C%20Docklands%20VIC%203008%2C%20Australia!5e0!3m2!1sen!2sus!4v1616681876631!5m2!1sen!2sus"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Box>
          </Box>
        </Container>
        <VenueView venueData={[
            {
                "category": "⚽ Football",
                "venues": [
                  {
                    "name": "Indoor Football Court",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400",
                    "link": "/ee/Tallinn/org-1/venue-1"
                  },
                  {
                    "name": "Indoor Football Court",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400",
                    "link": "/ee/Tallinn/org-1/venue-1"
                  },
                  {
                    "name": "Indoor Football Court",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400",
                    "link": "/ee/Tallinn/org-1/venue-1"
                  },
                  {
                    "name": "Indoor Football Court",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400",
                    "link": "/ee/Tallinn/org-1/venue-1"
                  },
                  {
                    "name": "Indoor Football Court",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400",
                    "link": "/ee/Tallinn/org-1/venue-1"
                  },
                  {
                    "name": "Indoor Football Court",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400",
                    "link": "/ee/Tallinn/org-1/venue-1"
                  },
                  {
                    "name": "Indoor Football Court",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400",
                    "link": "/ee/Tallinn/org-1/venue-1"
                  },
                  {
                    "name": "Indoor Football Court",
                    "rating": 4.8,
                    "deliveryFee": "Test Facility",
                    "deliveryTime": "8am - 11pm",
                    "image": "https://placehold.co/600x400",
                    "link": "/ee/Tallinn/org-1/venue-1"
                  },
                ]
              }
          ]} />
      </Box>
    </Box>
  );
};

export default Org;
