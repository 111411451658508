import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Button, CardMedia } from '@mui/material';

const slides = [
  { id: 1, type: 'text', title: 'Info box', content: 'The field is made in accordance with FIBA standards.' },
  { id: 2, title: 'Slide 2', content: 'Content of Slide 2' },
  { id: 3, type: 'image', url: 'https://placehold.co/600x400'},
];

const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    }, 3000); // Change slide every 3 seconds (3000 ms)

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []); // Run only once on component mount

  return (
    <div style={{ height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Card style={{ flexGrow: 1 }}>
        {slides[currentSlide].type === 'image' ? (<>
            <CardMedia
                component="img"
                height="100%"
                image={slides[currentSlide].url}
              />
          </>) : (
            <>
            <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              {slides[currentSlide].title}
            </Typography>
            <Typography variant="body1">
              {slides[currentSlide].content}
            </Typography>
          </CardContent>
          </>
        )}
      </Card>
      <Box display="flex" justifyContent="center" mt={2}>
        {slides.map((slide, index) => (
          <Button
            key={slide.id}
            onClick={() => setCurrentSlide(index)}
            style={{
              width: 10,
              height: 10,
              minWidth: 10, // Ensure button doesn't collapse
              borderRadius: '50%',
              margin: '0 5px',
              backgroundColor: index === currentSlide ? '#000' : '#ccc',
              border: 'none',
              padding: 0, // Remove padding to ensure perfect circle
            }}
          />
        ))}
      </Box>
    </div>
  );
};

export default Slideshow;
