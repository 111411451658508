import React from 'react';
import { Box } from '@mui/material';
import Search from '../components/Search';

const SearchPage = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Search></Search>
      </Box>
    </Box>
  );
}

export default SearchPage;
