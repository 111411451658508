import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Org from './pages/Org';
import Venue from './pages/Venue';
import SearchPage from './pages/SearchPage';
import NavbarMobile from './components/NavbarMobile';
import Book from './pages/Book';

const App = () => {
  return (
    <>
    <div>
        <Routes>
          <Route path="/" element={<Navigate to="/ee" replace />} />
          <Route path="/ee" element={<Home />} />
          <Route path="/ee/Tallinn/org-1" element={<Org />} />
          <Route path="/ee/Tallinn/org-1/venue-1" element={<Venue />} />
          <Route path="/ee/Tallinn/org-1/venue-1/book" element={<Book />} />
          <Route path="/ee/search" element={<SearchPage />} />
        </Routes>
        <NavbarMobile></NavbarMobile>
    </div>
    </>
  );
};

export default App;
