import { Box, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
const NavbarMobile = () => {
    return (
        <>
            <Box sx={{
                position: 'sticky',
                bottom: 0,
                zIndex: 1000,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'space-around',
                width: '100%',
                backgroundColor: 'white',
                borderTop: '1px solid #ccc',
                padding: '10px 0',
                borderRadius: 10
            }}>
                <Button component={Link} to="/ee/search" ><SearchIcon style={{ color: 'black' }} /></Button>
                <Link to='/' ><Button
                    startIcon={<Avatar src={'/image.png'} variant="square" />}>

                </Button>
                </Link>
                <Button color="inherit"><AccountCircleIcon /></Button>
            </Box>
        </>
    )
}

export default NavbarMobile;